import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { Product } from '../../store/product.types';
import {
  Observable,
  combineLatest,
  filter,
  map,
  mergeMap,
  of,
  switchMap,
  take,
} from 'rxjs';
import { appStore } from '../../../../app.store';
import { Store, StoreModule } from '@ngrx/store';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ProductOptionsFormSetting,
  UserInputTypes,
} from '../product-option.type';
import { AddToCartComponent } from '../add-to-cart/add-to-cart.component';
import {
  faPlus,
  faMinus,
  faArrowLeft,
  faArrowRight,
  faImage,
  faUpload,
  faRotate,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CountSelectorComponent } from '../count-selector/count-selector.component';
import {
  Concept,
  ConceptProfile,
  ConceptResult,
} from '../../../../concepts/store/concept.types';
import { LocalizerPipe } from '../../../../common/pipes/countryLocalePipe';
import { SelectionProductOptionComponent } from '../selection-product-option/selection-product-option.component';
import { getFormHelpers } from '../common/form.helper';
import { NgbAccordionModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import {
  ProductAction,
  ProductOptionUpdate,
} from '../../store/products.action';

@Component({
  selector: 'app-paint-by-numbers',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    StoreModule,
    ReactiveFormsModule,
    AddToCartComponent,
    CountSelectorComponent,
    FontAwesomeModule,
    LocalizerPipe,
    SelectionProductOptionComponent,
    NgbAccordionModule,
    NgbProgressbarModule
  ],
  templateUrl: './paint-by-numbers.component.html',
  styleUrl: './paint-by-numbers.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PaintByNumbersComponent implements OnInit {
  product: Observable<Product>;
  concept: Observable<Concept | null>;
  concept_result: Observable<ConceptResult | null>;

  formSettings: Observable<ProductOptionsFormSetting[]>;
  productSelectionsInvalid: Observable<boolean>;
  conceptProfile: Observable<ConceptProfile>;
  hasCompatibleConcepts: Observable<boolean>;

  conceptsInitialized: Observable<boolean>;

  faPlus = faPlus;
  faMinus = faMinus;
  faArrowLeft = faArrowLeft;
  faArrowRight = faArrowRight;
  faImage = faImage;
  faUpload = faUpload;
  faRotate = faRotate;
  dividerState = 50;
  descriptionAccordionCollapsed = true;

  conceptSelected: Observable<boolean>;
  allConceptsRendered: Observable<boolean>;

  constructor(
    private store: Store<appStore>,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.conceptSelected = this.route.queryParams.pipe(
      map((params) => 'concept_id' in params || false)
    );

    this.product = this.route.params.pipe(
      switchMap((params) =>
        this.store.select((state) =>
          state.eshopProductModule.products.find(
            (e) => e.id == params['id'] || e.slug == params['id']
          )
        )
      )
    );

    //Reset the selectable product options to make sure user clicks it so it dispatches the required events
    this.product.pipe(take(1)).subscribe((product) => {
      this.store.dispatch(ProductAction.updateProductOption({
        id: product.id,
        key: 'size',
        display_type: 'selection',
        option_type: 'enum',
        value: null
      }))
    })

    this.hasCompatibleConcepts = this.product.pipe(
      switchMap((product) =>
        this.store.select(
          (state) =>
            !!state.conceptModule.concepts.find((e) =>
              e.concept_format.product_slugs.includes(product.slug)
            )
        )
      )
    );

    this.concept = this.route.queryParams.pipe(
      filter((params) => !!params['concept_id']),
      mergeMap((params) =>
        this.store
          .select((state) => state.conceptModule)
          .pipe(
            map((conceptModuleState) => {
              {
                return {
                  conceptModuleState,
                  params,
                };
              }
            })
          )
      ),
      filter(
        ({ conceptModuleState, params }) => conceptModuleState.initialized
      ),
      map((val) =>
        val.conceptModuleState.concepts.find(
          (e) => e.id == val.params['concept_id']
        )
      )
    );

    this.conceptsInitialized = this.store.select((state) => state.conceptModule.initialized);

    this.allConceptsRendered = this.concept.pipe(map((concept) => concept.concept_results.every((e) => e.state == 'rendered')));

    this.concept_result = combineLatest([this.product, this.concept]).pipe(
      map(([product, concept]) => {
        if (!concept) {
          return null;
        }
        const found = concept.concept_results.find((e) =>
          e.concept_profile.product_option_restrictions.every((r) => {
            const option = product.product_options.find(
              (e) =>
                (e.key &&
                  e.isUserInput &&
                  [
                    UserInputTypes.DROPDOWN.toString(),
                    UserInputTypes.RADIO.toString(),
                    UserInputTypes.SELECTION.toString(),
                  ].includes(e.userInputType) &&
                  e.selected) ||
                (e.key &&
                  e.isUserInput &&
                  [UserInputTypes.INPUT.toString()].includes(e.userInputType))
            );

            if (!option) {
              return false;
            }

            switch (r.operand) {
              case 'eq': {
                return r.value === option.value;
              }
              case 'neq': {
                return r.value !== option.value;
              }
              case 'contains': {
                return r.value.includes(option.value);
              }
              case 'ncontains': {
                return !r.value.includes(option.value);
              }
              default: {
                return false;
              }
            }
          })
        );

        return found ?? null;
      })
    );

    this.formSettings = getFormHelpers(this.product);

    this.productSelectionsInvalid = this.product.pipe(
      switchMap((product) => {
        const values: { [key: string]: boolean } = {};
        product.product_options
          .filter((e) => e.isUserInput)
          .forEach((e) => {
            if (e.type == 'enum') {
              values[e.key] = !!e.selected || !!values[e.key];
            }
            if (e.type == 'string') {
              values[e.key] = !!e.value;
            }
          });
        return of(
          !Object.values(values).reduce((prev, cur) => {
            return prev && cur;
          }, true)
        );
      })
    );

    this.conceptProfile = this.product.pipe(
      map(
        (product) =>
          product.product_options.find((e) => e.key == 'concept_profile')
            .value as unknown as ConceptProfile
      )
    );

  }

  chooseFromGallery(): void {
    this.product.pipe(take(1)).subscribe((e) => {
      this.router.navigate(['/concepts'], {
        queryParams: {
          product: e.slug,
          name: e.name,
        },
      });
    });
  }

  createNew(): void {
    this.product.pipe(take(1)).subscribe((e) => {
      this.router.navigate(['/concepts', 'new'], {
        queryParams: {
          product: e.slug,
          name: e.name,
        },
      });
    });
  }

  optionSelected($event: ProductOptionUpdate) {
    this.product.pipe(take(1)).subscribe((product) => {
      this.store.dispatch(
        ProductAction.updateProductOption({
          ...$event,
          id: product.id,
        })
      );

      if ($event.key == 'size') {
        //Dependent events
        this.dividerState = 10;
        this.concept_result.pipe(take(1)).subscribe((concept_result) => {
          this.store.dispatch(
            ProductAction.updateProductOptions({
              updates: [
                {
                  id: product.id,
                  key: 'concept_profile',
                  option_type: 'string',
                  display_type: 'hidden',
                  value: concept_result.concept_profile,
                },
                {
                  id: product.id,
                  key: 'color_palette',
                  option_type: 'string',
                  display_type: 'hidden',
                  value: concept_result.color_palette,
                },
                {
                  id: product.id,
                  key: 'svg',
                  option_type: 'string',
                  display_type: 'hidden',
                  value: concept_result.svg,
                },
                {
                  id: product.id,
                  key: 'rendered',
                  option_type: 'string',
                  display_type: 'hidden',
                  value: concept_result.rendered,
                },
              ],
            })
          );
        });
      }
    });
  }
}
